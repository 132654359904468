<template>
  <div class="main d-flex justify-content-center">
    <div class="to-sell">
      <div class="text-center">
        <h3 class="title">講師登録</h3>
      </div>
      <div class="card">
        <div class="card-body">
          <div v-if="user.teacher">
            <div class="card-to-sell mb-3" v-if="user.teacher.status == 'pending'">
              <b>{{ $t("top_page.message.apply_teacher.pending") }}</b>
              <br />
            </div>
          </div>
          <div class="card-to-sell">
            <p>
              現在プランの品質を保つため、講師登録を審査制とさせていただいております。
            </p>
            <p>
              申し込みフォームより必要事項を記入の上、審査をお申し込みください。
            </p>
            <p>運営担当者よりご連絡させていただきます。</p>
            <p>
              ※すべての募集者登録希望が返信/ご登録招待とならないことあらかじめご了承ください
            </p>
          </div>
          <div class="term-service text-center pt-2">
            <router-link to="/term-of-service">利用規約</router-link> |
            <router-link to="/handling-pi">個人情報の取り扱いについて</router-link>
          </div>
        </div>
        <div class="pb-5 text-center">
          <button v-if="user.stripe &&
            user.stripe.details_submitted == true
            " @click="handleVerify" class="btn-apply-teacher" :disabled="verifying">
            修正する →
          </button>
          <Button class="btn-apply-teacher" :name="$t('top_page.apply_to_teacher')" @handleClick="handleClick"
            v-else/>
        </div>
      </div>
    </div>
    <MetaTags />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ToSellLesson",
  data() {
    return {
      verifying: false,
      isVisible: false,
      isApply: true,
    };
  },
  computed: {
    isMember() {
      return this.user.info;
    },
    ...mapGetters({
      user: "member/getUser",
    }),
  },
  created() {
    if (this.isMember) {
      this.checkApply();
      this.getUserInfo();
    }
  },
  methods: {
    ...mapActions({
      verifyStripe: "member/verifyStripe",
      getUserInfo: "member/getUserInfo",
    }),
    async handleVerify() {
      if (this.verifying) return;
      this.verifying = true;
      await this.verifyStripe((response) => {
        let { data } = response;
        window.location.href = data.account_link;
      });
      this.verifying = false;
    },
    handleClick() {
      this.$router.push("/apply-to-teacher");
    },
    actionCancel() {
      this.isVisible = false;
    },
    checkApply() {
      if (this.user.teacher && this.user.teacher.status !== "inactive") {
        this.isApply = false;
      }
    },
  },
  watch: {
    user(data) {
      if (
        data.teacher &&
        data.teacher.allow_sell == 1 &&
        data.stripe.is_account_verify == true
      ) {
        this.$router.push("/my-page/lesson");
      }
      this.checkApply();
    },
  },
};
</script>
